import React, { useState } from 'react';
import './SuccessStage.css'

const DownloadButton = ({ s3AudioUrl }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleDownload = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await fetch(s3AudioUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'manifestation_meditation_full.mp3');
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
      setIsLoading(false);
    } catch (error) {
      console.error('Download failed:', error);
      setIsLoading(false);
    }
  };

  // Conditional arrow or spin
  return (
    <a className="styled-button" href={s3AudioUrl} onClick={handleDownload}>
      Download Audio
      {isLoading ? (
        <div className="spinner"></div>  // Show spinner instead of arrow
      ) : (
        <span className="arrow">➔</span>  // Show arrow when not loading
      )}
    </a>
  );
};

export default DownloadButton;