import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { StatusBar } from 'react-native';
import HomePage from './components/HomePage';
import Chatbot from './components/Chatbot';
import AffirmationsAnimation from './components/AffirmationsAnimation';
import DetailedPage from './components/DetailedPage';
import AudioPlayer from './components/AudioPlayer';
import SuccessStage from './components/SuccessStage';
import Cancel from './components/Cancel';
import './index.css';

function App() {
  const [stage, setStage] = useState('home');
  const [affirmations, setAffirmations] = useState([]);
  const [audioUrl, setAudioUrl] = useState(null);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [contactId, setContactId] = useState(0);
  const [affirmationStartTime, setAffirmationStartTime] = useState(null);
  const [audioUrlReceived, setAudioUrlReceived] = useState(false);
  const [audioUrlObject, setAudioUrlObject] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [price, setPrice] = useState(null);
  const [isNavigatingToCheckout, setIsNavigatingToCheckout] = useState(false);
  const [previousStages, setPreviousStages] = useState([]);

  // Load state from sessionStorage when the app loads
  useEffect(() => {
    const savedStage = sessionStorage.getItem('currentStage');
    if (savedStage) {
      loadSavedState();
    }
  }, []);

  const loadSavedState = () => {
    const savedStage = sessionStorage.getItem('currentStage');
    const savedAffirmations = sessionStorage.getItem('affirmations');
    const savedUserName = sessionStorage.getItem('userName');
    const savedUserEmail = sessionStorage.getItem('userEmail');
    const savedContactId = sessionStorage.getItem('contactId');
    const savedAffirmationStartTime = sessionStorage.getItem('affirmationStartTime');
    const savedAudioUrl = sessionStorage.getItem('audioUrl');

    setStage(savedStage);
    if (savedAffirmations) setAffirmations(JSON.parse(savedAffirmations));
    if (savedUserName) setUserName(savedUserName);
    if (savedUserEmail) setUserEmail(savedUserEmail);
    if (savedContactId) setContactId(parseInt(savedContactId, 10));
    if (savedAffirmationStartTime) setAffirmationStartTime(parseInt(savedAffirmationStartTime, 10));
    if (savedAudioUrl) {
      setAudioUrl(savedAudioUrl);
      setAudioUrlReceived(true);
      setAudioUrlObject(URL.createObjectURL(new Blob([savedAudioUrl])));
    }
  };

  // Handle browser back button and refresh
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (!isNavigatingToCheckout) {
        e.preventDefault();
        e.returnValue = '';
        saveCurrentState();
      }
    };

    const handlePopState = (e) => {
      if (!isNavigatingToCheckout) {
        if (previousStages.length > 0) {
          e.preventDefault();
          e.returnValue = ''; // Show confirmation prompt
          setShowPopup(true);
        } else {
          setShowPopup(true);
        }
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [isNavigatingToCheckout, previousStages]);

  const saveCurrentState = () => {
    sessionStorage.setItem('currentStage', stage);
    sessionStorage.setItem('affirmations', JSON.stringify(affirmations));
    sessionStorage.setItem('userName', userName);
    sessionStorage.setItem('userEmail', userEmail);
    sessionStorage.setItem('contactId', contactId.toString());
    if (affirmationStartTime) {
      sessionStorage.setItem('affirmationStartTime', affirmationStartTime.toString());
    }
    if (audioUrl) {
      sessionStorage.setItem('audioUrl', audioUrl);
    }
  };

  const handleContinue = () => {
    setShowPopup(false);
    if (previousStages.length > 0) {
      const lastStage = previousStages[previousStages.length - 1];
      setPreviousStages(prev => prev.slice(0, -1));
      setStage(lastStage);
    } else {
      loadSavedState();
    }
  };

  const handleStartOver = () => {
    setShowPopup(false);
    sessionStorage.clear();
    setStage('home');
    setAffirmations([]);
    setAudioUrl(null);
    setUserName('');
    setUserEmail('');
    setContactId(0);
    setAffirmationStartTime(null);
    setAudioUrlReceived(false);
    setAudioUrlObject(null);
    history.push('/');
  };

  // Your existing handler functions remain unchanged
  const handleChatComplete = (generatedAffirmations, userName, userEmail, contactId) => {
    setPreviousStages(prev => [...prev, stage]);
    setAffirmations(generatedAffirmations);
    setUserName(userName);
    setUserEmail(userEmail);
    setContactId(contactId);
    setStage('affirmations');
    setAffirmationStartTime(Date.now());
    saveCurrentState();
  };

  const handleAudioPathsReceived = (url) => {
    setAudioUrl(url);
    setAudioUrlReceived(true);
    const urlObj = URL.createObjectURL(url);
    setAudioUrlObject(urlObj);
  };

  useEffect(() => {
    let transitionTimer;

    if (stage === 'affirmations') {
      const checkConditions = () => {
        const currentTime = Date.now();
        const elapsedTime = currentTime - affirmationStartTime;

        if (elapsedTime >= 30000 && audioUrlObject) {
          // Transition after at least 30 seconds if the URL object is ready
          setStage('audio');
        } else if (elapsedTime < 30000) {
          // If less than 30 seconds have passed, continue checking
          transitionTimer = setTimeout(checkConditions, 1000);
        } else if (!audioUrlObject) {
          // Continue checking every 100 milliseconds if the URL object is not yet ready
          transitionTimer = setTimeout(checkConditions, 1000);
        }
      };

      // Start the initial check
      transitionTimer = setTimeout(checkConditions, 100);
    }

    return () => {
      if (transitionTimer) clearTimeout(transitionTimer);
    };
  }, [stage, audioUrlObject, affirmationStartTime]);

  // Keep other handler functions unchanged
  const handleChatStart = () => {
    setPreviousStages(prev => [...prev, stage]);
    setStage('chat');
  };

  const handleUnlockClick = () => {
    setPreviousStages(prev => [...prev, stage]);
    setStage('detailed');
  };

  const handleCheckoutClick = (priceOption) => {
    setPrice(priceOption);
    setIsNavigatingToCheckout(true);
    setPreviousStages(prev => [...prev, stage]);
    setStage('checkout');
  };

  const handleSuccess = (customerName, customerEmail, contactId) => {
    setStage('success');
    console.log(`Customer: ${customerName}, Email: ${customerEmail}`);
  };

  const handleCancel = () => {
    setStage('cancel');
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const session_id = urlParams.get('session_id');
    if (session_id) {
      fetch(`/api/success?session_id=${session_id}`)
        .then(response => response.json())
        .then(data => {
          if (data.status === 'success') {
            handleSuccess(data.customer_name, data.customer_email, data.contact_id);
          }
        })
        .catch(error => console.error('Error:', error));
    }
  }, []);

  useEffect(() => {
    const path = location.pathname;
    if (path === '/success') {
      setStage('success');
    } else if (path === '/') {
      setStage('home');
    }
    // Add other routes as needed
  }, [location.pathname]);

  useEffect(() => {
    // Trigger event when stage changes
    if (window.fbq) {
      switch(stage) {
        case 'home':
          window.fbq('track', 'Homepage', {
            content_name: 'Homepage'
          });
          break;

        case 'chat':
          window.fbq('track', 'Chatbot', {
            content_category: 'Chatbot',
            content_name: 'Start Chat'
          });
          break;

        case 'affirmations':
          window.fbq('trackCustom', 'AffirmationsPreview', {
            content_name: 'Affirmations Generated',
            num_items: affirmations.length
          });
          break;

        case 'audio':
          window.fbq('track', 'AudioPreview', {
            content_name: 'Preview Audio',
            content_type: 'audio'
          });
          break;

        case 'detailed':
          window.fbq('track', 'SalesPage', {
            content_name: 'Sales Page',
            content_type: 'product'
          });
          break;

        case 'checkout':
          // Assuming you have a price variable available
          window.fbq('track', 'InitiateCheckout', {
            content_type: 'product',

          });
          break;

        case 'success':
          // Assuming you have price and userEmail variables available
          window.fbq('track', 'Purchase', {
            content_type: 'product',
            content_name: 'Download Page',
            value: price
          });
          break;

        default:
          // Optionally track unknown stages
          console.log(`No FB Pixel event defined for stage: ${stage}`);
      }
    }
  }, [stage, affirmations.length, price]);

  return (
    <div className="App">
      <StatusBar translucent={true} />
      <div className="app-container">
        {stage === 'home' && <HomePage onClickStart={handleChatStart} />}
        {stage === 'chat' && <Chatbot onComplete={handleChatComplete} />}
        {stage === 'affirmations' && (
          <AffirmationsAnimation
            affirmations={affirmations}
            userName={userName}
            userEmail={userEmail}
            waitTime={30000}
            onAudioUrlReceived={handleAudioPathsReceived}
            contactId={contactId}
          />
        )}
        {stage === 'audio' && <AudioPlayer audioUrlObject={audioUrlObject} onUnlockClick={handleUnlockClick} userName={userName}/>}
        {stage === 'detailed' && <DetailedPage onCheckoutClick={handleCheckoutClick} userName={userName} userEmail={userEmail} audioUrlObject={audioUrlObject} audioUrl={audioUrl} contactId={contactId}/>}
        {stage === 'checkout' && <div>Redirecting to checkout...</div>}
        {stage === 'success' && (
          <SuccessStage
            userName={userName}
            userEmail={userEmail}
            contactId={contactId}
          />
        )}
        {stage === 'cancel' && <Cancel />}
        {showPopup && (
          <div className="popup-pause">
            <div className="popup-pause-content">
              <h2>Manifestation in progress</h2>
              <p>Do you want to continue from where you left off or start over?</p>
              <button className="styled-button" onClick={handleContinue}>Yes, continue</button>
              <button className="styled-button" onClick={handleStartOver}>No, start over</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default function WrappedApp() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={App} />
        <Route path="/success" component={App} />
      </Switch>
    </Router>
  );
}