import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import './SuccessStage.css';
import DownloadButton from './DownloadButton';

const SuccessStage = ({ userName, userEmail, contactId }) => {
  const [affirmations, setAffirmations] = useState([]);
  const [s3AudioUrl, setS3AudioUrl] = useState(null);
  const [jobId, setJobId] = useState(null);
  const [error, setError] = useState(null);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const chatSummary = useRef('');
  const chatHistory = useRef([]);

  useEffect(() => {
    const storedAudioUrl = localStorage.getItem('s3AudioUrl');
    if (storedAudioUrl) {
      setS3AudioUrl(storedAudioUrl);
    } else {
      getAffirmations();
    }
  }, [userName, userEmail, contactId]);

  const getAffirmations = async () => {
    try {
      const response = await axios.post(
        `${backendUrl}/api/affirmations`,
        {
          summary: chatSummary.current,
          chat_history: chatHistory.current,
          user_name: userName,
          user_email: userEmail,
          affirmations_count: 20
        },
        { timeout: 60000 }
      );

      const generatedAffirmations = response.data.affirmations;
      setAffirmations(generatedAffirmations);
      fetchAudioUrl(generatedAffirmations);
    } catch (error) {
      console.error('Error fetching affirmations:', error);
      setError('Failed to generate affirmations. Please try again.');
    }
  };

  const fetchAudioUrl = async (generatedAffirmations) => {
    try {
      const response = await axios.post(
        `${backendUrl}/api/generate_audio`,
        {
          affirmations: generatedAffirmations,
          user_name: userName,
          user_email: userEmail,
          context: 'full',
          contact_id: contactId
        },
        {
          headers: {
            'Content-Type': 'application/json'
          },
          responseType: 'json',
          timeout: 30000
        }
      );

      if (response.status === 202) {
        const { job_id } = response.data;
        setJobId(job_id);
        pollJobStatus(job_id);
      } else {
        throw new Error('Unexpected response from server');
      }
    } catch (error) {
      console.error('Error initiating audio generation:', error);
      setError('Failed to start audio generation. Please try again.');
    }
  };

  const pollJobStatus = async (jobId) => {
    const intervalId = setInterval(async () => {
      try {
        const response = await axios.get(`${backendUrl}/api/task_status/${jobId}`);
        const { status, s3_url, error: jobError } = response.data;

        if (status === 'completed' && s3_url) {
          setS3AudioUrl(s3_url);
          localStorage.setItem('s3AudioUrl', s3_url);
          clearInterval(intervalId);
        } else if (status === 'failed') {
          setError(jobError || 'Audio generation failed. Please try again.');
          clearInterval(intervalId);
        }
      } catch (error) {
        console.error('Error checking job status:', error);
        setError('Failed to check audio generation status. Please refresh the page.');
        clearInterval(intervalId);
      }
    }, 10000);

    return () => clearInterval(intervalId);
  };

  // The loading state UI
  const LoadingState = () => (
    <div>
      <div className="logo"><span className="logo-head">manifest</span>lab</div>
      <div className="container">
        <h1>Thanks For Your Order {userName}!</h1>
        <h2>Your Complete Manifestation Meditation Is Being Created...</h2>
        <p className="instructions">
          (Please wait 30-45 seconds for your meditation to be generated)
        </p>
        <div className="loading-spinner"></div>
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );

  // The success state UI
  const SuccessState = () => (
    <div>
      <div className="logo"><span className="logo-head">manifest</span>lab</div>
      <div className="container">
        <h1>Thanks For Your Order {userName}!</h1>
        <h2>Your Complete Manifestation Meditation Is Ready!</h2>
        <p className="instructions">
          (Click on the 'Download Audio' button below to get your meditation)
        </p>
        <DownloadButton s3AudioUrl={s3AudioUrl} />
        <p className="email-instructions">
          <b>Please check your email inbox</b> for your purchase receipt, as well as instructions on how to redeem additional credits (if you purchased a bundle).
        </p>
        <p className="contact">
          Still need help? Email contact@manifestlab.io
        </p>
      </div>
    </div>
  );

  // Render based on s3AudioUrl availability
  return s3AudioUrl ? <SuccessState /> : <LoadingState />;
};

export default SuccessStage;