import React, { useEffect, useRef, useState } from 'react';
import './AffirmationsAnimation.css';
import axios from 'axios';

const AffirmationsAnimation = ({affirmations, userName, userEmail, waitTime, scrollSpeed = 8, onAudioUrlReceived, contactId}) => {
  const ribbonRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const hasFetchedAudioUrl = useRef(false);

  useEffect(() => {
    const ribbon = ribbonRef.current;
    const sentences = [...affirmations, ...affirmations, ...affirmations, ...affirmations];

    sentences.forEach((sentence) => {
      const div = document.createElement('div');
      div.className = 'sentence';
      div.innerText = sentence;
      ribbon.appendChild(div);
    });

    let animationFrameId;
    let startTime;

    const animate = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const elapsedTime = timestamp - startTime;

      const loadingProgress = (elapsedTime / waitTime) * 100;
      setProgress(Math.min(loadingProgress, 100));

      const totalScrollDistance = scrollSpeed * ribbon.offsetHeight / sentences.length;
      const scrollAmount = (elapsedTime * totalScrollDistance / waitTime) % ribbon.offsetHeight;
      ribbon.style.transform = `translateY(-${scrollAmount}px)`;

      if (elapsedTime < waitTime) {
        animationFrameId = requestAnimationFrame(animate);
      }
    };

    animationFrameId = requestAnimationFrame(animate);

    return () => {
      while (ribbon.firstChild) {
        ribbon.removeChild(ribbon.firstChild);
      }
      cancelAnimationFrame(animationFrameId);
    };
  }, [affirmations, waitTime, scrollSpeed]);

  useEffect(() => {
    if (hasFetchedAudioUrl.current) return;

    const fetchAudioUrl = async () => {
    try {
      const response = await axios.post(`${backendUrl}/api/generate_audio`, {
        affirmations: affirmations,
        user_name: userName,
        user_email: userEmail,
        contact_id: contactId,
        context: "preview"
        }, {
            headers: {
                'Content-Type': 'application/json'
            },
            withCredentials: true,
            responseType: 'blob',  // Important to get binary data as a Blob
            timeout: 90000
        });
        if (response.status === 200) {
            hasFetchedAudioUrl.current = true;
            onAudioUrlReceived(response.data);
        } else {
            throw new Error('Error retrieving audio');
        }
    } catch (error) {
        console.error('Error fetching audio URL:', error);
    }
    };

    fetchAudioUrl();
  }, [backendUrl, affirmations, userName, userEmail, onAudioUrlReceived, contactId]);

  return (
    <div>
      <div className="logo"><span className="logo-head">manifest</span>lab</div>
      <div className="container">
        <div className="heading">
          <div className="user-name">One Moment, {userName}!</div>
          <div className="generating-text">Generating your personalized affirmations...</div>
        </div>
        <div className="progress-container">
          <div
            className="progress-text"
            style={{ left: `${progress}%` }}
          >
            {`${Math.round(progress)}%`}
          </div>
          <div className="progress-bar">
            <div className="progress" style={{ width: `${progress}%` }}></div>
          </div>
        </div>
        <div className="affirmations-animation">
          <div className="ribbon" ref={ribbonRef}></div>
        </div>
      </div>
    </div>
  );
};

export default AffirmationsAnimation;