import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';

// Initialize fbq globally
window.fbq = window.fbq || function() {
  (window.fbq.q = window.fbq.q || []).push(arguments);
};

// Facebook Pixel Code
(function(f, b, e, v, n, t, s) {
  if (f.fbq) return;
  n = f.fbq = function() {
    n.callMethod ?
    n.callMethod.apply(n, arguments) : n.queue.push(arguments);
  };
  if (!f._fbq) f._fbq = n;
  n.push = n;
  n.loaded = !0;
  n.version = '2.0';
  n.queue = [];
  t = b.createElement(e);
  t.async = !0;
  t.src = v;
  s = b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t, s);
})(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

window.fbq('init', '972869991248773');
window.fbq('track', 'PageView');

// Create and append noscript pixel
const noscript = document.createElement('noscript');
const img = document.createElement('img');
img.height = 1;
img.width = 1;
img.style.display = 'none';
img.src = 'https://www.facebook.com/tr?id=972869991248773&ev=PageView&noscript=1';
noscript.appendChild(img);
document.head.appendChild(noscript);

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
