import './Chatbot.css';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

const Chatbot = ({ onComplete }) => {
  const [chatHistory, setChatHistory] = useState([]);
  const [userInput, setUserInput] = useState('');
  const textareaRef = useRef(null);
  const [latestAssistantMessage, setLatestAssistantMessage] = useState(null);
  const [isAssistantTyping, setIsAssistantTyping] = useState(false);
  const [waitingForAssistant, setWaitingForAssistant] = useState(false);
  const [currentChat, setCurrentChat] = useState([]);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const chatHistoryRef = useRef(null);
  const latestMessageRef = useRef(null);
  const [emailCollected, setEmailCollected] = useState(false);
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [chatSummary, setChatSummary] = useState('');
  const [affirmationsList, setAffirmationsList] = useState([]);
  const [progressStep, setProgressStep] = useState(1);

  const calculateProgress = (chatHistory) => {
    const assistantMessageCount = chatHistory.filter(message => message.role === 'assistant').length;
    const progress = assistantMessageCount - 1;
    return progress;
  };

  useEffect(() => {
    const newProgress = calculateProgress(chatHistory);
    setProgressStep(newProgress);
  }, [chatHistory]);


  const scrollToBottom = () => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [userInput, latestAssistantMessage]);

  useEffect(() => {
    if (chatHistory.length === 0) {
      const initialAssistantMessage = "Hi there :) Welcome to ManifestLab, the world's first personalized manifestation platform.\n\n" +
                "In just a few minutes, you're going to receive a fully customized manifestation meditation, " +
                "designed to help you turn your deepest desires into reality.\n\n" +
                "Before we get started, what's your name?";
      typeAssistantMessage(initialAssistantMessage);
    }
  }, []);

  const typeAssistantMessage = async (message) => {
    setCurrentChat([]);
    setIsAssistantTyping(true);
    let typedMessage = '';
    for (let i = 0; i < message.length; i++) {
      typedMessage += message[i];
      setLatestAssistantMessage({ role: 'assistant', content: typedMessage });
      await new Promise(resolve => setTimeout(resolve, 20)); // Adjust typing speed here
    }
    if (!emailCollected) {
      setChatHistory((prevChatHistory) => [...prevChatHistory, { role: 'assistant', content: message }]);
    }
    setCurrentChat([{ role: 'assistant', content: message }]);
    setIsAssistantTyping(false);
  };

  useEffect(() => {
    if (textareaRef.current && !isAssistantTyping && !waitingForAssistant) {
      textareaRef.current.focus();
    }
  }, [isAssistantTyping, waitingForAssistant]);

  const getAffirmations = async (userEmail) => {
    try {
      console.log('Sending affirmation request with:', {
        summary: chatSummary,
        chat_history: chatHistory,
        user_name: userName,
        user_email: userEmail,
        affirmations_count: 10
      });
      const response = await axios.post(`${backendUrl}/api/affirmations`, {
        summary: chatSummary,
        chat_history: chatHistory,
        user_name: userName,
        user_email: userEmail,
        affirmations_count: 10
      }, { timeout: 60000 });

      console.log('Affirmations:', response.data);
      const generatedAffirmations = response.data.affirmations;
      const contactId = response.data.contact_id;
      setAffirmationsList(generatedAffirmations);
      onComplete(generatedAffirmations, userName, userEmail, contactId);
    } catch (error) {
      console.error('Error fetching affirmations:', error);
    }
  };

  const sendMessage = async () => {
    if (userInput) {
      const newChatHistory = [...chatHistory, { role: 'user', content: userInput }];
      setChatHistory(newChatHistory);
      setCurrentChat([...currentChat, { role: 'user', content: userInput }])

      if (emailCollected) {
        const userEmail = userInput.trim();
        setUserEmail(userEmail);
        console.log("User email set as: ", userEmail, userInput);
        setUserInput('');
        setWaitingForAssistant(true);
        getAffirmations(userEmail);
        return;
      }

      setUserInput('');
      setWaitingForAssistant(true);

      try {
        const response = await axios.post(`${backendUrl}/api/chat`, {
          user_input: userInput,
          chat_history: newChatHistory
        }, { timeout: 60000 }); // Increase timeout to 60 seconds

        if (response.data.user_name) {
          setUserName(response.data.user_name);
        }

        if (response.data.context === 'email') {
          setEmailCollected(true);
          setChatSummary(response.data.summary);
        }

        const assistantMessage = response.data.message;

        setWaitingForAssistant(false);
        typeAssistantMessage(assistantMessage);

      } catch (error) {
        console.error('Error:', error);
        setIsAssistantTyping(false);
      }
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default form submission
      if (!isAssistantTyping && !waitingForAssistant) sendMessage();
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [userInput]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${Math.min(textarea.scrollHeight, 200)}px`;
      textarea.focus();
    }
  };

  const handleInput = (e) => {
    setUserInput(e.target.value);
  };

  const preventScroll = (e) => {
    e.preventDefault();
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  return (
    <div className="chat-page-container">
      <div className="chat-progress-container">
        {[...Array(8)].map((_, index) => (
          <div
            key={index}
            className={`chat-progress-bar ${index <= progressStep ? 'active' : ''}`}
          />
        ))}
      </div>
      <div className="logo"><span className="logo-head">manifest</span>lab</div>
      <div className="chat-container">
        <div className="chat-history" id="chat-history" ref={chatHistoryRef}>
          {currentChat.map((message, index) => (
            <div key={index} className={`message ${message.role} ${message === latestAssistantMessage ? 'latest' : ''}`}>
              {message.content}
            </div>
          ))}
          {waitingForAssistant && (
            <div className="message assistant typing">
              <span className="typing-indicator"></span>
            </div>
          )}
          {isAssistantTyping && latestAssistantMessage && (
            <div className="message assistant latest" ref={latestMessageRef}>
              {latestAssistantMessage.content}
            </div>
          )}
        </div>
        {!isAssistantTyping && !waitingForAssistant && (
          <div className="input-container">
            <textarea
            ref={textareaRef}
            id="user-input"
            value={userInput}
            onChange={handleInput}
            onKeyPress={handleKeyPress}
            onFocus={preventScroll}
            placeholder="Type here..."
            rows={1}
            />
            <button onClick={sendMessage}>➤</button>
          </div>
        )}
        {!isAssistantTyping && !waitingForAssistant && (
          <div className="hint">Or Hit Enter</div>
        )}
      </div>
    </div>
  );
};

export default Chatbot;